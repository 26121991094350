@import '../../node_modules/@angular/material/theming';

// *** Global fonts   *** //
$dsb-font: 'DM Sans', arial, sans-serif;

// *** Global variables   *** //
$dsb-titlebar-height: 6rem;
$dsb-titlebar-height-tablet: 7.8rem;
$dsb-navigation-button-height: 6.7rem;
$dsb-navigation-button-height-tablet: 9.2rem;
$dsb-input-border-radius: 4px;

// *** Global colors   *** //
$dsb-invalid-color: #fec90e;
$dsb-required-star-color: #f75f55;
$dsb-red-color: #e60202;
$dsb-white: #fff;
$dsb-border-color: #cdcdcd;
$dsb-stepper-bottom-border-color: #d8d6dc;
$dsb-time-slot-border-color: #a9a9a9;
$dsb-grey-text-color: #656565;
$dsb-light-grey-text-color: #707070;
$dsb-light-grey-border-color: #979797;
$dsb-location-address-text: #717171;
$dsb-stepper-number-color: #746d83;
$dsb-step-title-sub-color: #3e3e3e;
$dsb-black-color: #222;
$dsb-form-calendar-arrow-color: #000;
$dsb-neutral-300: #e0e0e0;
$dsb-neutral-400: #bdbdbd;

// *** Universal variables *** //
$dsb-gap-8: 0.8rem;
$dsb-gap-16: 1.6rem;

// *** Theme palette ***//
$theme-primary-color-map: (
    50: var(--theme-primary-color-50),
    100: var(--theme-primary-color-100),
    200: var(--theme-primary-color-200),
    300: var(--theme-primary-color-300),
    400: var(--theme-primary-color-400),
    500: var(--theme-primary-color-500),
    600: var(--theme-primary-color-600),
    700: var(--theme-primary-color-700),
    800: var(--theme-primary-color-800),
    900: var(--theme-primary-color-900),
    a100: var(--theme-primary-color-a100),
    a200: var(--theme-primary-color-a200),
    a400: var(--theme-primary-color-a400),
    a700: var(--theme-primary-color-a700),
    contrast: (
        50: var(--theme-primary-color-contrast-50),
        100: var(--theme-primary-color-contrast-100),
        200: var(--theme-primary-color-contrast-200),
        300: var(--theme-primary-color-contrast-300),
        400: var(--theme-primary-color-contrast-400),
        500: var(--theme-primary-color-contrast-500),
        600: var(--theme-primary-color-contrast-600),
        700: var(--theme-primary-color-contrast-700),
        800: var(--theme-primary-color-contrast-800),
        900: var(--theme-primary-color-contrast-900),
        a100: var(--theme-primary-color-contrast-a100),
        a200: var(--theme-primary-color-contrast-a200),
        a400: var(--theme-primary-color-contrast-a400),
        a700: var(--theme-primary-color-contrast-a700),
    ),
);
$theme-accent-color-map: (
    50: var(--theme-accent-color-50),
    100: var(--theme-accent-color-100),
    200: var(--theme-accent-color-200),
    300: var(--theme-accent-color-300),
    400: var(--theme-accent-color-400),
    500: var(--theme-accent-color-500),
    600: var(--theme-accent-color-600),
    700: var(--theme-accent-color-700),
    800: var(--theme-accent-color-800),
    900: var(--theme-accent-color-900),
    a100: var(--theme-accent-color-a100),
    a200: var(--theme-accent-color-a200),
    a400: var(--theme-accent-color-a400),
    a700: var(--theme-accent-color-a700),
    contrast: (
        50: var(--theme-accent-color-contrast-50),
        100: var(--theme-accent-color-contrast-100),
        200: var(--theme-accent-color-contrast-200),
        300: var(--theme-accent-color-contrast-300),
        400: var(--theme-accent-color-contrast-400),
        500: var(--theme-accent-color-contrast-500),
        600: var(--theme-accent-color-contrast-600),
        700: var(--theme-accent-color-contrast-700),
        800: var(--theme-accent-color-contrast-800),
        900: var(--theme-accent-color-contrast-900),
        a100: var(--theme-accent-color-contrast-a100),
        a200: var(--theme-accent-color-contrast-a200),
        a400: var(--theme-accent-color-contrast-a400),
        a700: var(--theme-accent-color-contrast-a700),
    ),
);
$theme-warn-color-map: (
    50: var(--theme-warn-color-50),
    100: var(--theme-warn-color-100),
    200: var(--theme-warn-color-200),
    300: var(--theme-warn-color-300),
    400: var(--theme-warn-color-400),
    500: var(--theme-warn-color-500),
    600: var(--theme-warn-color-600),
    700: var(--theme-warn-color-700),
    800: var(--theme-warn-color-800),
    900: var(--theme-warn-color-900),
    a100: var(--theme-warn-color-a100),
    a200: var(--theme-warn-color-a200),
    a400: var(--theme-warn-color-a400),
    a700: var(--theme-warn-color-a700),
    contrast: (
        50: var(--theme-warn-color-contrast-50),
        100: var(--theme-warn-color-contrast-100),
        200: var(--theme-warn-color-contrast-200),
        300: var(--theme-warn-color-contrast-300),
        400: var(--theme-warn-color-contrast-400),
        500: var(--theme-warn-color-contrast-500),
        600: var(--theme-warn-color-contrast-600),
        700: var(--theme-warn-color-contrast-700),
        800: var(--theme-warn-color-contrast-800),
        900: var(--theme-warn-color-contrast-900),
        a100: var(--theme-warn-color-contrast-a100),
        a200: var(--theme-warn-color-contrast-a200),
        a400: var(--theme-warn-color-contrast-a400),
        a700: var(--theme-warn-color-contrast-a700),
    ),
);
$theme-primary-palette: mat-palette($theme-primary-color-map);
$theme-accent-palette: mat-palette($theme-accent-color-map);
$theme-warn-palette: mat-palette($theme-warn-color-map);
