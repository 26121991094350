@use '../../styles/variables' as v;
@use '../../../node_modules/@angular/material' as mat;
@use 'sass:map';
@use '../../styles/mixins' as m;

.mat-horizontal-stepper-header-container {
    padding: 1.6rem 3.2rem;
    gap: v.$dsb-gap-8;
    border-bottom: 1px solid v.$dsb-stepper-bottom-border-color;

    .mat-step-header {
        .mat-step-icon .mat-icon {
            font-size: 2.8rem;
            height: 2.8rem;
            width: 2.8rem;
            color: mat.get-color-from-palette(v.$theme-primary-palette, 500);
        }

        &:hover:not([aria-disabled]) {
            background-color: unset;
        }

        .mat-step-icon-content > svg {
            fill: mat.get-color-from-palette(v.$theme-primary-palette, 500);
        }
    }

    .mat-horizontal-stepper-header {
        height: unset;
        padding: unset;
        pointer-events: none;

        .mat-step-icon {
            margin-right: 0;
            background-color: unset;
            color: v.$dsb-stepper-number-color;
            border: 1px solid v.$dsb-border-color;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 1.8rem;
            box-sizing: border-box;

            &.mat-step-icon-state-edit {
                border: none;
            }
        }

        .mat-step-label {
            min-width: unset;
        }

        &[aria-selected='true'] + .mat-stepper-horizontal-line {
            border-top-style: dotted;
            border-top-color: mat.get-color-from-palette(v.$theme-primary-palette, 500);
        }

        &[ng-reflect-state='edit'] + .mat-stepper-horizontal-line {
            border-top-color: mat.get-color-from-palette(v.$theme-primary-palette, 500);
        }
    }

    .mat-stepper-horizontal-line {
        margin: unset;
        min-width: 1.6rem;
    }
}

.mat-horizontal-stepper-wrapper {
    height: 100%;
    width: 100%;

    .mat-horizontal-content-container {
        padding: 0 1.6rem 1.6rem;
        overflow-y: overlay;

        .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
            height: 100%;
        }
    }
}

.mat-stepper-horizontal {
    font-family: v.$dsb-font !important;
    width: 100%;
}

mat-step-header[aria-selected='true'] {
    .mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
        border: 1px solid mat.get-color-from-palette(v.$theme-primary-palette, 500);
        color: mat.get-color-from-palette(v.$theme-primary-palette, 500);
    }
}

@include m.respond-to(tablet) {
    .mat-horizontal-stepper-header-container {
        padding: 1.6rem calc((100% - 50rem) / 2);
        gap: v.$dsb-gap-16;

        .mat-horizontal-stepper-header {
            .mat-step-icon {
                font-size: 1.6rem;
                line-height: 2.25rem;
                height: 3.2rem;
                width: 3.2rem;

                svg {
                    height: 3.2rem;
                    width: 3.2rem;
                }
            }
        }
    }

    .mat-horizontal-stepper-wrapper {
        .mat-horizontal-content-container {
            padding: 0 3.2rem 3.2rem;
        }
    }
}

@include m.respond-to(desktop) {
    .mat-horizontal-stepper-header-container {
        padding: 1.6rem calc((100% - 40rem) / 2);
    }

    .mat-horizontal-stepper-wrapper {
        .mat-horizontal-content-container {
            padding: 0;

            .mat-horizontal-stepper-content {
                padding: 0 calc((100% - 44rem) / 2);
            }
        }
    }
}
