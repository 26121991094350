@use '../../../node_modules/@angular/material' as mat;
@use '../../styles/variables' as v;

.mat-calendar-content.calendar-content-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30rem;
    height: 28rem;

    .calendar-spinner {
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;
        position: relative;
        animation: rotate 1s linear infinite;
    }

    .calendar-spinner::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        inset: 0;
        border-radius: 50%;
        border: 0.8rem solid mat.get-color-from-palette(v.$theme-primary-palette);
        animation: prix-clip-fix 2s linear infinite;
    }
    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes prix-clip-fix {
        0% {
            clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        }

        25% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
        }

        50% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
        }

        75% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
        }

        100% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        }
    }
}
