/* You can add global styles to this file, and also import other style files */
@use './styles/variables' as v;
@use './styles/mixins' as m;

/* Material override imports */
@import '../../node_modules/@angular/material/theming';
@import './assets/material-override/mat-horizontal-stepper';
@import './assets/material-override/mat-radio-button';
@import './assets/material-override/mat-button-toggle';
@import './assets/material-override/mat-calendar';
@import './assets/material-override/mat-checkbox';

@include mat-core;

$dynamic-theming-demo-theme: mat-light-theme(
    (
        color: (
            primary: v.$theme-primary-palette,
            accent: v.$theme-accent-palette,
            warn: v.$theme-warn-palette,
        ),
    )
);

/* Include theme styles for core and each component used in your app.
   Alternatively, you can import and @include the theme mixins for each component
   that you are using. */
@include angular-material-theme($dynamic-theming-demo-theme);

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;
}

body {
    font-family: v.$dsb-font;
    margin: 0;
    font-weight: normal;
}

h1,
h2,
h3 {
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
}

.confirmation-component-container {
    display: flex;
    overflow-x: scroll;
    width: 100%;
}

.content-component-container {
    width: 100%;
}

.disable-click {
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: none;
}

@include m.respond-to(desktop) {
    /* custom scrollbar */
    ::-webkit-scrollbar {
        width: 2rem;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: v.$dsb-neutral-300;
        border-radius: 2rem;
        border: 0.6rem solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: v.$dsb-neutral-400;
    }
}
